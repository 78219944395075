@import './cssGlobal/color';
@import './cssGlobal/media';
@import './cssGlobal/sizes';
@import './cssGlobal/type';

html {
  -webkit-font-smoothing: antialiased;
  height: 100%;
  scroll-behavior: smooth;

  & > body {
    height: 100%;
  }
}

body {
  color: var(--color-black);
  font-family: sans-serif;
}

:global(#root) {
  height: 100%;
}
