*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
}

html {
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font: inherit;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ol,
ul {
  list-style-type: none;
  padding-left: 0;
}

button,
input,
optgroup,
select,
textarea {
  appearance: none;
  background: none;
  border: 0;
  color: currentColor;
  font: inherit;
  padding: 0;
  vertical-align: middle;
}

label {
  cursor: pointer;
}

address { font: inherit; }

img,
video,
svg {
  display: block;
  max-width: 100%;
}

img,
video {
  height: auto;
}
