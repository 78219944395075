.component_rootImpl{
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  z-index: 0;

  & > .main {
    flex-grow: 1;
  }
}

.main {
  display: flex;
  flex-direction: column;

  & > * {
    height: 100%;
    flex: 1;
  }
}